import React from "react";
import ServiceSidebar from "./ServiceSidebar";
import benchmark from "../../assets/images/projects/benchmark.jpg";
import { useTranslation } from "gatsby-plugin-react-i18next";

const BI = () => {
	const { t } = useTranslation();

	return (
		<section className="services-details-area pt-100px ptb-100">
			<div className="container">
				<div className="row">
					<div className="col-lg-8 col-md-12">
						<div className="services-details-desc">
							<span className="sub-title">
								<h2>{t("home.BIh21")}</h2>
							</span>
							<h3>{t("home.BIh31")}</h3>
							<p>{t("home.BIp1")}</p>

							<div className="row align-items-center">
								<div className="col-lg-6 col-md-6">
									<div className="image">
										<img src={benchmark} alt="about" />
									</div>
								</div>
								<div className="col-lg-6 col-md-6">
									<div className="content">
										<h3>{t("home.BIh32")}</h3>
										{/*
                                        <li>{t('home.BIi1')}</li>
                                            <li>{t('home.BIi2')}</li>
                                            <li>{t('home.BIi3')}</li>
                                        */}
										<li>{t("home.BIh22")}</li>
									</div>
								</div>
							</div>
							<h3>{t("home.BIh33")}</h3>
							<p>{t("home.BIp2")}</p>
							<p>{t("home.BIp3")}</p>

							{/*
                            <h3>Application Areas</h3>

                            <div className="row">
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-factory"></i>
                                        </div>
                                        Manufacturing
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-hospital"></i>
                                        </div>
                                        Healthcare
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-tracking"></i>
                                        </div>
                                        Automobile
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-investment"></i>
                                        </div>
                                        Banking
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-house"></i>
                                        </div>
                                        Real Estate
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-order"></i>
                                        </div>
                                        Logistics
                                    </div>
                                </div>
                            </div>
*/}
						</div>
					</div>

					<div className="col-lg-4 col-md-12">
						<ServiceSidebar />
					</div>
				</div>
			</div>
		</section>
	);
};

export default BI;
